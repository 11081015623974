import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'gatsby-theme-material-ui';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { makeStyles } from '@material-ui/core/styles';

import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  listItem: {
    margin: 0,
    padding: 0,
  },
  listItemButton: {
    padding: theme.spacing(0.5, 2.5),
  },
  listItemIcon: {
    minWidth: 32,
    fontSize: 20,
  },
  divider: {
    margin: theme.spacing(1, 0),  // or padding?
  },
}));

export default function MappedItemsList({
  items,
  itemIconClassName,
  itemKeyPrefix,
  ...restListProps // like aria-label and aria-labelledby
}) {
  const classes = useStyles();

  return (
    <List {...restListProps}>
      {items.map((item, index) => {
        const ListItemButton = React.forwardRef((props, ref) => (
          <Button ref={ref} to={item.to} target={item.target} {...props} />
        ));

        const isDivider = item.name.length === 0;
        const key = `${itemKeyPrefix}-${isDivider ? `divider-${index}` : item.name}`;

        return (
          <ListItem
            className={classNames(classes.listItem, isDivider && classes.divider)}
            key={key}
          >
            {!isDivider && (
              <ListItemButton className={classes.listItemButton}>
                {item.icon && (
                  <ListItemIcon
                    className={classes.listItemIcon}
                  >
                    <item.icon className={itemIconClassName} style={item.iconStyle} />
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={item.name}
                  primaryTypographyProps={{ variant: 'body1' }}
                />
              </ListItemButton>
            )}
          </ListItem>
        );
      })}
    </List>
  );
}

MappedItemsList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.elementType,
    iconStyle: PropTypes.style,
  })).isRequired,
  itemIconClassName: PropTypes.string,
  itemKeyPrefix: PropTypes.string,
};

MappedItemsList.defaultProps = {
  itemIconClassName: undefined,
  itemKeyPrefix: 'mapped-items-list',
};
