import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'gatsby-theme-material-ui';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';

import MenuIcon from '@material-ui/icons/Menu';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { FaGraduationCap } from 'react-icons/fa';
import { BsPencilSquare } from 'react-icons/bs';
import { GiAtomicSlashes } from 'react-icons/gi';
import { IoMailOutline } from 'react-icons/io5';
import {
  FcStackOfPhotos,
  FcScatterPlot,
  FcMindMap,
  FcParallelTasks,
  FcSmartphoneTablet,
  FcTemplate,
  FcCollaboration,
  FcOpenedFolder,
  FcManager,
} from 'react-icons/fc';

import DropdownMenu from './DropdownMenu';

import NeuralLogoImage from '../images/neural-logo.png';
import GoogleScholarImage from '../images/google-scholar-icon.ico';
import ResearchGateImage from '../images/researchgate-icon.png';
import DrawerMenuEntry from './DrawerMenuEntry';

const ResearchGateIcon = () => (
  <img
    src={ResearchGateImage}
    alt="ResearchGate Icon"
    style={{ width: 18, height: 18, margin: 0, filter: 'saturate(10) contrast(200%) !important' }}
  />
);

const NeuralLogoIcon = () => (
  <img src={NeuralLogoImage} alt="Neural Icon" style={{ width: 50, height: 50, margin: '0 12px 0 0', padding: 0 }} />
);

const GoogleScholarIcon = () => (
  <img src={GoogleScholarImage} alt="Google Scholar Icon" style={{ width: 20, height: 20, margin: 0, padding: 0 }} />
);

const researchMenuItems = [
  {
    name: 'Google Scholar',
    to: 'https://scholar.google.pl/citations?user=dHckAD4AAAAJ',
    target: '_blank',
    icon: GoogleScholarIcon,
  },
  {
    name: 'ResearchGate',
    to: 'https://www.researchgate.net/profile/Krzysztof_Dobosz',
    target: '_blank',
    icon: ResearchGateIcon,
    iconStyle: { filter: 'saturate(10) contrast(200%) !important' },
  },
  {
    name: 'Researcher ID',
    to: 'http://www.researcherid.com/rid/P-5323-2016',
    target: '_blank',
    icon: FcManager,
    iconStyle: { filter: 'saturate(1.2) contrast(200%)' },
  },
  {
    name: '',  // divider
  },
  {
    name: 'Viser Toolbox',
    to: 'https://www.is.umk.pl/~kdobosz/visertoolbox',
    target: '_blank',
    icon: FcScatterPlot,
  },
  {
    name: 'Research Wiki',
    to: 'http://kdobosz.wikidot.com',
    target: '_blank',
    icon: FcStackOfPhotos,
  },
];

const teachingMenuItems = [
  {
    name: 'Pracownia programowania zespołowego',
    to: '/pracownia-programowania-zespolowego/',
    icon: FcCollaboration,
  },
  {
    name: 'Programowanie urządzeń mobilnych',
    to: '/programowanie-urzadzen-mobilnych/',
    icon: FcSmartphoneTablet,
  },
  {
    name: 'Opracowywanie serwisów sieciowych',
    to: '/opracowywanie-serwisow-sieciowych/',
    icon: FcTemplate,
  },
  {
    name: 'Sztuczne sieci neuronowe',
    to: '/sztuczne-sieci-neuronowe/',
    icon: FcMindMap,
    iconStyle: { filter: 'saturate(1.2) contrast(200%)' },
  },
  {
    name: 'Programowanie obiektowe',
    to: '/programowanie-obiektowe/',
    icon: FcParallelTasks,
  },
  {
    name: '',  // divider
  },
  {
    name: 'Repozytorium projektów studenckich',
    to: 'https://www.is.umk.pl/~kdobosz/repo',
    target: '_blank',
    icon: FcOpenedFolder,
  },
];

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: theme.palette.background.default,
  },
  title: {
    fontSize: 20,
  },
  navList: {
    display: 'flex',
    flexDirection: 'row',
    '& li': {
      margin: 0,
    },
  },
  menuItem: {
    textTransform: 'uppercase',
    padding: theme.spacing(1, 2.5),
  },
  dropdownMenuItemIcon: {
    filter: 'saturate(1.2)',
  },
  drawer: {
    flexShrink: 0,
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerEntryMenuIcon: {
    fontSize: 16,
  },
}));

const BlogMenuButton = (props) => (
  <Button
    {...props}
    to="https://programistyka.eu"
    target="blank"
    startIcon={<BsPencilSquare style={{ fontSize: 18 }} />}
  >
    Blog
  </Button>
);

const ContactMenuButton = (props) => (
  <Button
    {...props}
    to="/contact"
    startIcon={<IoMailOutline style={{ fontSize: 22 }} />}
  >
    Contact
  </Button>
);

export default function Header({ siteTitle }) {
  const classes = useStyles();
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = React.useState(false);

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <NeuralLogoIcon />
          <Button
            to="/"
            className={classes.title}
            // startIcon={<HomeIcon />}
            size="large"
          >
            {siteTitle}
          </Button>
          <div className={classes.root} />
          <nav>
            {!isSmDown
              ? (
                <List className={classes.navList} disablePadding>
                  <ListItem disableGutters>
                    <DropdownMenu
                      title="Research"
                      className={classes.menuItem}
                      icon={GiAtomicSlashes}
                      itemIconClassName={classes.dropdownMenuItemIcon}
                      items={researchMenuItems}
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <DropdownMenu
                      title="Teaching"
                      className={classes.menuItem}
                      icon={FaGraduationCap}
                      iconSize={22}
                      itemIconClassName={classes.dropdownMenuItemIcon}
                      items={teachingMenuItems}
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <BlogMenuButton className={classes.menuItem} />
                  </ListItem>
                  <ListItem disableGutters>
                    <ContactMenuButton className={classes.menuItem} />
                  </ListItem>
                </List>
              )
              : (
                <>
                  <IconButton
                    color="primary"
                    aria-label="open drawer"
                    edge="end"
                    onClick={setOpen}
                    className={classes.menuButton}
                  >
                    <MenuIcon />
                  </IconButton>
                  <SwipeableDrawer
                    className={classes.drawer}
                    variant="temporary"
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    anchor="right"
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                  >
                    <Divider />
                    <DrawerMenuEntry
                      title="Research"
                      className={classes.menuItem}
                      icon={GiAtomicSlashes}
                      items={researchMenuItems}
                      itemIconClassName={classes.drawerEntryMenuIcon}
                    />
                    <Divider />
                    <DrawerMenuEntry
                      title="Teaching"
                      className={classes.menuItem}
                      icon={FaGraduationCap}
                      items={teachingMenuItems}
                      itemIconClassName={classes.drawerEntryMenuIcon}
                    />
                    <Divider />
                    <BlogMenuButton className={classes.menuItem} />
                    <Divider />
                    <ContactMenuButton className={classes.menuItem} />
                    <Divider />
                  </SwipeableDrawer>
                </>
              )}
          </nav>
        </Toolbar>
      </AppBar>
    </div>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
};
