import React from 'react';
import PropTypes from 'prop-types';
import {
  and,
  nonNegativeInteger,
} from 'airbnb-prop-types';

import { Link } from 'gatsby-theme-material-ui';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

import { BiCopyright } from 'react-icons/bi';

const baseTransitionTime = 0.7;
const opacityTransitionTime = {
  show: 3,
  hide: 1,
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    padding: theme.spacing(0.7, 1.7),
    backgroundColor: theme.palette.background.solid,
    boxShadow: theme.custom.shadows[10],
    borderRadius: `${theme.shape.borderRadius}px 0 0 0`,
    height: '3.1em',
    width: 55,
    transition: `all ${baseTransitionTime}s ease`,
    '&:hover': {
      width: 380,
    },
    '&:hover $extendedCopyright': {
      opacity: 1,
    },
    '&:hover $shortCopyright': {
      opacity: 0,
      left: 83,
      fontSize: 20,
      padding: theme.spacing(0.8),
      transition: `all ${baseTransitionTime}s ease, opacity ${opacityTransitionTime.show}s linear`,
    },
  },
  divider: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  typography: {
    position: 'relative',
    cursor: 'default',
  },
  extendedCopyright: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(0.8),
    position: 'absolute',
    top: 0,
    left: 0,
    overflow: 'hidden',
    opacity: 0,
    transition: `all ${baseTransitionTime}s linear`,
  },
  shortCopyright: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: 0,
    left: 0,
    transition: `all ${baseTransitionTime}s ease, opacity ${opacityTransitionTime.hide}s linear`,
    fontSize: 24,
    padding: theme.spacing(0.3),
  },
  inlineCopyrightIcon: {
    fontSize: 20,
    margin: '0 4px',
    verticalAlign: 'text-top',
  },
}));

export default function Footer({ startYear, author, contactPage }) {
  const classes = useStyles();
  let authorEntry = author || null;
  if (authorEntry && contactPage) {
    authorEntry = (
      <>
        <span className={classes.divider}>|</span>
        <Link
          underline="none"
          variant="subtitle1"
          to={contactPage}
          title="Contact page"
        >
          {authorEntry}
        </Link>
      </>
    );
  }
  return (
    <Box className={classes.wrapper}>
      <footer>
        <Typography className={classes.typography} variant="subtitle1" color="textPrimary">
          <Box component="div" whiteSpace="nowrap" className={classes.extendedCopyright}>
            Copyright
            <BiCopyright className={classes.inlineCopyrightIcon} />
            {!startYear || `${startYear}-`}
            {new Date().getFullYear()}
            {!authorEntry || authorEntry}
          </Box>
          <Box className={classes.shortCopyright}>
            <BiCopyright />
          </Box>
        </Typography>
      </footer>
    </Box>
  );
}

Footer.propTypes = {
  startYear: and([PropTypes.number, nonNegativeInteger]),
  author: PropTypes.string,
  contactPage: PropTypes.string,
};

Footer.defaultProps = {
  startYear: null,
  author: null,
  contactPage: null,
};
