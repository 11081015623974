/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import Header from './Header';
import Footer from './Footer';
import './layout.css';

import BackgroundImage from '../images/awesome-backround.png';

const mobileFirefoxRegEx = /^(?=.*\bFirefox\b)(?=.*\bMobile\b).*$/i;
const isMobileFirefox = (typeof window !== 'undefined') && mobileFirefoxRegEx.test(global.navigator.userAgent);

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundColor: 'black',
    backgroundAttachment: isMobileFirefox ? 'scroll' : 'fixed', // fixed is causing a bottom gap in Mobile Firefox (height of the address bar?)
    minHeight: '100vh',
    margin: 0,
    padding: 0,
  },
  container: {
    minHeight: '100vh',
    position: 'relative',
    overflow: 'hidden',
    boxSizing: 'border-box',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.custom.shadows[20],
    padding: '80px 70px 70px 70px',
    margin: '0 auto !important',
    [theme.breakpoints.down('sm')]: {
      padding: '70px 20px 40px 20px',
    },
  },
}));

const Layout = ({ children }) => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          startYear
          author
        }
      }
    }
  `);

  return (
    <div className={classes.wrapper}>
      <Header siteTitle={data.site.siteMetadata.title} />
      <Container className={classes.container}>
        <main>{children}</main>
      </Container>
      <Footer
        startYear={data.site.siteMetadata.startYear}
        author={data.site.siteMetadata.author}
        contactPage="/contact"
      />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
