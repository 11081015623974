import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'gatsby-theme-material-ui';

import Box from '@material-ui/core/Box';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';

import MappedItemsList from './MappedItemsList';

export default function DrawerMenuEntry({
  title,
  className,
  icon,
  items,
  itemIconClassName,
}) {
  const MenuIcon = icon;  // doesn't work with a lowercase letter
  const [open, setOpen] = React.useState(false);

  return (
    <Box
      sx={{
        bgcolor: open ? 'background.lighter' : null,
        pb: open ? 2 : 0,
      }}
    >
      <Button
        className={className}
        style={{ width: '100%', textAlign: 'left' }}
        startIcon={icon && <MenuIcon className={itemIconClassName} />}
        // alignItems="flex-start"
        onClick={() => setOpen(!open)}
        sx={{
          px: 3,
          pt: 2.5,
          pb: open ? 0 : 2.5,
          '&:hover, &:focus': { '& svg': { opacity: open ? 1 : 0 } },
        }}
      >
        {title}
        <KeyboardArrowDown
          style={{ // sx doesn't work here for some reason...
            // mr: -1,
            // opacity: 0,
            transform: open ? 'rotate(-180deg)' : 'rotate(0)',
            transition: '0.2s',
          }}
        />
      </Button>
      {open && (
        <MappedItemsList
          items={items}
          itemIconClassName={itemIconClassName}
          itemKeyPrefix={`drawer-menu-entry-${title.toLowerCase()}`}
        />
      )}
    </Box>
  );
}

DrawerMenuEntry.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  icon: PropTypes.elementType,
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.elementType,
    iconStyle: PropTypes.style,
  })).isRequired,
  itemIconClassName: PropTypes.string,
};

DrawerMenuEntry.defaultProps = {
  className: undefined,
  icon: undefined,
  itemIconClassName: undefined,
};
