import React from 'react';
import PropTypes from 'prop-types';

import {
  and,
  nonNegativeInteger,
} from 'airbnb-prop-types';

import { Button } from 'gatsby-theme-material-ui';

import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';

import { makeStyles } from '@material-ui/core/styles';

import MappedItemsList from './MappedItemsList';

const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: theme.zIndex.drawer,
  },
  paper: {
    backgroundColor: theme.palette.background.default,
    boxShadow: '0 3px 6px 3px black',
  },
}));

export default function DropdownMenu({
  title,
  icon,
  iconSize,
  component,
  className,
  itemIconClassName,
  items,
  openTimeout,
  closeTimeout,
}) {
  const classes = useStyles();
  const MenuIcon = icon;  // doesn't work with a lowercase letter
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mouseOverButton, setMouseOverButton] = React.useState(false);
  const [mouseOverMenu, setMouseOverMenu] = React.useState(false);

  const handleClose = () => {
    setAnchorEl(null);
    setMouseOverButton(false);
    setMouseOverMenu(false);
  };

  const enterButton = (event) => {
    setAnchorEl(event.currentTarget);
    setMouseOverButton(true);
  };

  const enterMenu = () => {
    setMouseOverMenu(true);
  };

  const leaveButton = () => {
    if (closeTimeout > 0) {
      setTimeout(() => {
        setMouseOverButton(false);
      }, closeTimeout);  // it can froze if time === 0
    } else {
      setMouseOverButton(false);
    }
  };

  const leaveMenu = () => {
    if (closeTimeout > 0) {
      setTimeout(() => {
        setMouseOverMenu(false);
      }, closeTimeout);
    } else {
      setMouseOverMenu(false);
    }
  };

  const isOpen = mouseOverButton || mouseOverMenu;

  const titleLowerCase = title.toLowerCase();
  const buttonId = `dropdown-${titleLowerCase}`;
  const popperId = isOpen ? `poper-${titleLowerCase}` : undefined;

  return (
    <>
      <Button
        component={component}
        id={buttonId}
        className={className}
        aria-controls={popperId}
        aria-haspopup="true"
        startIcon={icon && <MenuIcon style={iconSize && { fontSize: iconSize }} />}
        onMouseEnter={enterButton}
        onMouseLeave={leaveButton}
        onClick={handleClose}
      >
        {title}
      </Button>
      <Popper
        className={classes.popper}
        id={popperId}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        // keepMounted
        transition
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            timeout={openTimeout}
          >
            <Paper
              className={classes.paper}
              elevation={6}
              onMouseEnter={enterMenu}
              onMouseLeave={leaveMenu}
            >
              <MappedItemsList
                items={items}
                itemIconClassName={itemIconClassName}
                itemKeyPrefix={buttonId}
                aria-label={titleLowerCase}
                aria-labelledby={buttonId}
              />
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

DropdownMenu.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  component: PropTypes.elementType,
  icon: PropTypes.elementType,
  iconSize: PropTypes.number,
  itemIconClassName: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.elementType,
    iconStyle: PropTypes.style,
  })).isRequired,
  openTimeout: and([PropTypes.number, nonNegativeInteger]),
  closeTimeout: and([PropTypes.number, nonNegativeInteger]),
};

DropdownMenu.defaultProps = {
  className: undefined,
  icon: undefined,
  iconSize: undefined,
  component: 'a',  // better for nav than a button?
  itemIconClassName: undefined,
  openTimeout: 250,
  closeTimeout: 0,
};
